import React, { useEffect, useRef } from 'react';
import { ModelManager } from '@adobe/aem-spa-page-model-manager';

import '@marriott/global-styles/dist/marriot.global.css';
import '@glidejs/glide/src/assets/sass/glide.core.scss';
import '@glidejs/glide/src/assets/sass/glide.theme.scss';

import '../styles/cobrand.scss';

import { defineComponentMapping } from '../import-components';
import path from 'path';
import {
  canUseDOM,
  CustomModelClient,
  getEnvProp,
  populatePIdataLayer,
  respGridUtil,
  UserSessionProvider,
} from '@marriott/mi-headless-utils';
import {
  useDCAPageModelStore,
  useClientEnvVarsStore,
  PageModelStoreType,
  PageModelState,
  createPageModelStore,
  PageModelContext,
} from '@marriott/mi-store-utils';
path.resolve('./next.config.js');
import Cookies from 'js-cookie';
import { envVarKeys } from '../_constants/envVarKeys';
import { RESOLVED_URL } from '@marriott/mi-cobrand-components';
import { Types } from '@marriott/mi-ui-library';

declare global {
  interface Window {
    jQuery: unknown;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $: any;
    deployedEnvType: string;
    dataLayer?: Record<string, unknown>;
    targetGlobalSettings: Record<string, unknown>;
  }
}

if (canUseDOM) window.$ = window.jQuery = require('jquery-slim/dist/jquery.slim.min.js');

const modelClient = new CustomModelClient(process.env.NEXT_PUBLIC_AEM_HOST);

if (process.env.NODE_ENV !== 'test') {
  ModelManager.initializeAsync({
    modelClient,
  });
}

const App = props => {
  const { Component, pageProps } = props;
  const {
    model,
    isAuthorMode,
    resourceUrl,
    query,
    NEXT_PUBLIC_FETCH_OFFERS_API_URL,
    showError,
    apolloEnvVars,
    ecmUserStatus,
    customerID,
    setEcmCookie,
    removeEcmCookie,
    resolvedUrl,
  } = pageProps;
  const allowedComponents = model?.cqItems?.root?.[':items']?.responsivegrid?.allowedComponents?.components;
  const isStickyCardEnabled = model?.enableStickyHeader && model?.enableStickyHeader === 'true' ? true : false;
  const enableCardComparisonFeature =
    model?.enableCardComparisonFeature && model?.enableCardComparisonFeature === 'true';
  const dataLayer = pageProps?.dataLayer;

  if (model?.seo && model?.seo.uri) {
    const allUri = model?.seo.uri;
    const langList = Object.keys(allUri);
    model['hreflangList'] = langList?.map(key => ({
      href: allUri[key],
      hreflang: key,
    }));
  }
  const respGridModel = respGridUtil(model?.cqItems?.root?.[':items']?.responsivegrid);
  const respGridModelClone = JSON.parse(JSON.stringify(respGridModel)); //To deep clone the model
  const store = useRef<PageModelStoreType>();
  const initialPageModel: PageModelState = {
    pageModel: respGridModel,
  };
  const isChasePage = resolvedUrl?.includes(RESOLVED_URL?.CHASECREDITCARDURL);
  const isAmexPage = resolvedUrl?.includes(RESOLVED_URL?.AMEXCREDITCARDURL);

  if (!store.current) {
    store.current = createPageModelStore(initialPageModel);
  }

  const setPageModel = useDCAPageModelStore(state => state.setPageModel);
  if (setPageModel) {
    setPageModel(respGridModelClone);
  }

  const envVars = apolloEnvVars ? apolloEnvVars : {};
  envVars['NEXT_PUBLIC_FETCH_OFFERS_API_URL'] = NEXT_PUBLIC_FETCH_OFFERS_API_URL;
  envVars['NEXT_PUBLIC_ENABLE_WEBSDK'] =
    getEnvProp(envVarKeys.NEXT_PUBLIC_ENABLE_WEBSDK) === 'true' && model?.enableWebSDK === 'true';
  const setEnvVars = useClientEnvVarsStore(state => state.setEnvVars);

  useEffect(() => {
    if (model?.templateName === 'headless-next-js-cdp-template') {
      window.targetGlobalSettings = {
        aepSandboxId: '4b4e08f2-8e19-4baf-8e08-f28e19fbafe2',
        aepSandboxName: 'aepdevelopment',
      };
    }
  }, [model]);

  if (setEnvVars) {
    setEnvVars(envVars);
  }

  if (canUseDOM) {
    if (setEcmCookie) {
      Cookies.set('ecmUserStatus', ecmUserStatus);
      Cookies.set('customerId', customerID);
    }
    if (removeEcmCookie) {
      Cookies.remove('ecmUserStatus');
      Cookies.remove('customerId');
    }
  }

  if (canUseDOM) {
    populatePIdataLayer();
  }

  defineComponentMapping(
    allowedComponents,
    resourceUrl,
    isAuthorMode,
    dataLayer,
    {
      isStickyCardEnabled,
      enableCardComparisonFeature,
      allQueryParams: query,
    },
    showError,
    resolvedUrl,
    {
      isTextCentered: isChasePage,
      featureTitleFontSize: isChasePage ? Types.size.large : Types.size.extraLarge,
      isTickIconFilled: isChasePage,
    },
    {
      isChaseButton: isChasePage,
      isAmexButton: isAmexPage,
      isTertiaryLinksVisible: isAmexPage,
      isImagehidden: isAmexPage,
      isPointDescriptionVisible: isAmexPage,
      isBonusPointsLabelVisible: isAmexPage,
      isAnnualFeeVisible: isAmexPage,
    }
  );
  return (
    // For ACDL setup - @see https://marriottcloud.atlassian.net/browse/DXP-16772
    <UserSessionProvider pageProps={pageProps} includePiData={true}>
      <PageModelContext.Provider value={store.current}>
        <Component {...pageProps} />
      </PageModelContext.Provider>
    </UserSessionProvider>
  );
};

export default App;
